*{
    margin: 0;
    padding: 0;
    
  }
  
  .pdf-container{
    height: 800px;
    background-color: #e4e4e4;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .error-msg{
    width: 100%;
    color: red;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    justify-content: center;
  }

  .template-name{
    color: black;
    font-size: 15px;
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }