.pdfReaderContainer{
    margin-top: 40px!important;
    justify-content: center;
    align-items: center;
    width: 100%;
}

@media only screen and (min-width: 768px) {
  .pdfReaderContainer {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .pdfReaderContainer {
    margin-top: 30px;

  }
}

