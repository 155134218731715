
.element-for-print {
  width: 50px;
  min-height: 840px;
}

.page {
    padding: 10px;
    page-break-after: always;
    display: block;
  }

  /* .html2canvas-container { 
    width: 3000px; 
    height: 3000px; 
} */
.html2canvas-container { 
  width: 3000px; 
  height: 3000px; 
}
