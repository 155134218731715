.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 20px;

  width: 40%;
  height: 30%;
  animation: popup-appear 0.5s ease-in-out;
}

@keyframes popup-appear {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.drop-file-input {
  position: relative;
  /* width: 400px; */
  /* height: 320px; */
  border: 2px dashed var(--border-color);
  border-radius: 20px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--input-bg);
}

.drop-file-input input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.drop-file-input:hover,
.drop-file-input.dragover {
  opacity: 0.6;
}

.drop-file-input__label {
  text-align: center;
  color: var(--txt-second-color);
  font-weight: 600;
  padding: 10px;
}

.drop-file-input__label img {
  width: 200px;
}

.drop-file-preview {
  margin-top: 10px;
}

.drop-file-preview p {
  font-weight: 500;
}

.drop-file-preview__title {
  margin-bottom: 20px;
}

.drop-file-preview__item {
  position: relative;
  display: flex;
  margin-bottom: 10px;
  background-color: var(--input-bg);
  padding: 15px;
  border-radius: 20px;
}

.drop-file-preview__item img {
  width: 50px;
  margin-right: 20px;
}

.drop-file-preview__item__info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.drop-file-preview__item__del {
  background-color: var(--box-bg);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: var(--box-shadow);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.drop-file-preview__item:hover .drop-file-preview__item__del {
  opacity: 1;
}

#my-div {
  background-color: #f00;
  width: 200px;
  height: 200px;
}
a.fill-div {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
}

.drop-file-input-next {
  position: relative;
  border: 2px dashed var(--border-color);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--input-bg);
  padding: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.drop-file-input-next:hover {
  background-color: var(--input-bg-hover);
}

.drop-file-input__label-next {
  text-align: center;
  color: var(--txt-color);
  font-weight: 600;
}

.drop-file-input__label-next img {
  width: 200px;
  margin-bottom: 20px;
}

.drop-file-input__label-next h2 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.drop-file-input__label-next p {
  font-size: 1.2rem;
  line-height: 1.5;
}
.drop-file-input-next {
  position: relative;
  border: 2px dashed var(--border-color);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--input-bg);
  padding: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.drop-file-input-next:hover {
  background-color: var(--input-bg-hover);
}

.drop-file-input__label-next {
  text-align: center;
  color: var(--txt-color);
  font-weight: 600;
}

.drop-file-input__label-next img {
  width: 200px;
  margin-bottom: 20px;
}

.drop-file-input__label-next h2 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.drop-file-input__label-next p {
  font-size: 1.2rem;
  line-height: 1.5;
}
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(237, 239, 243, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.loader-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
  margin-right: 10px;
}

.loader-text {
  font-size: 1.2rem;
}

.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.spinner {
  margin: 0 auto;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 5px solid rgba(255, 255, 255, 0.3);
  border-top-color: #ffffff;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
