/* Container for the buttons */
.button-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px; /* Adjust as needed */
    width: 200px;  /* Adjust as needed */
    overflow: hidden;
  }
  
  /* Buttons within the container */
  .animated-button {
    position: absolute;
    opacity: 0;
    transform: translateY(100px); /* Start below the view */
    transition: opacity 0.5s ease, transform 0.5s ease;
  }
  
  /* Animation class applied when toggle button is clicked */
  .animate .animated-button {
    opacity: 1;
  }
  
  /* Specific positioning for each button in the half-circle path */
  .animate .animated-button:nth-child(1) {
    animation: halfCircle 1s ease forwards;
    animation-delay: 0s; /* Immediate animation */
  }
  
  .animate .animated-button:nth-child(2) {
    animation: halfCircle 1s ease forwards;
    animation-delay: 0.5s; /* Staggered animation */
  }
  
  .animate .animated-button:nth-child(3) {
    animation: halfCircle 1s ease forwards;
    animation-delay: 1s; /* Staggered animation */
  }
  
  /* Keyframes for half-circle animation */
  @keyframes halfCircle {
    0% {
      transform: translate(0, 100px); /* Start below */
    }
    50% {
      transform: translate(100px, 0); /* Move to the right and up */
    }
    100% {
      transform: translate(200px, -100px); /* Complete the half-circle */
    }
  }